<template>
  <go-back :title="'AVISOS DE PAGO'"></go-back>

  <div class="mt-2">
    <router-link :to="{ name: 'avisos-de-pago-nuevo' }">
      <button-blue>NUEVO AVISO DE PAGO</button-blue>
    </router-link>
  </div>
  <div class="mt-4 max-w-screen-md">
    <tabla></tabla>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import GoBack from "@/components/GoBack.vue";

import Tabla from "@/components/PanelAvisosHomeTabla.vue";
import ButtonBlue from "./ButtonBlue.vue";

export default defineComponent({
  components: {
    Tabla,
    GoBack,
    ButtonBlue,
  },
  setup() {
    return {};
  },
});
</script>
