<template>
  <!-- Filtros -->
  <div
    class="
      grid grid-cols-1 sm:grid-cols-2
      md:flex
      flex-wrap
      items-end
      mb-2
      text-sm
      gap-y-2 gap-x-2
      avisos-tabla-filters-container
    "
  >
    <div
      v-for="filtro in filtrosToRender"
      :key="filtro.id"
      :class="[{ date: filtro.type === 'datepicker' }, 'item flex flex-1']"
    >
      <span>{{ titulos[filtro.id] }}</span>
      <c-select
        v-if="filtro.type === 'c-select'"
        class="w-full"
        :id="filtro.id"
        :canAll="true"
        :fullWidth="true"
        :options="optionSelects[filtro.id]"
        v-model:selected="filtrosActivos[filtro.id]"
      ></c-select>
      <datepicker
        v-if="filtro.type === 'datepicker'"
        :canNull="true"
        :widthFull="true"
        v-model:value="filtrosActivos[filtro.id]"
      />
    </div>
  </div>

  <div class="text-sm">
    <!-- Columnas -->
    <div class="w-full flex flex-wrap bg-white border rounded-t select-none">
      <div
        v-for="column in columns"
        :key="column.id"
        class="
          p-2
          flex flex-wrap
          justify-center
          items-end
          gap-x-1
          flex-1
          cursor-pointer
        "
        @click="changeSortBy(column.id)"
      >
        {{ column.text }}
        <!-- Iconos sort -->
        <div v-if="column.id === sortBy">
          <svg
            v-if="sortAsc"
            xmlns="http://www.w3.org/2000/svg"
            class="h-4 w-4"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h7a1 1 0 100-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z"
            />
          </svg>
          <svg
            v-else
            xmlns="http://www.w3.org/2000/svg"
            class="h-4 w-4"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h5a1 1 0 000-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM13 16a1 1 0 102 0v-5.586l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 101.414 1.414L13 10.414V16z"
            />
          </svg>
        </div>
      </div>
    </div>
    <!-- Rows -->
    <div
      v-for="(row, index) in tableRowsSort"
      :key="index"
      class="
        flex flex-col
        mobile:flex-row
        w-full
        bg-white
        even:bg-gray-100
        border border-bg-gray-dark
        hover:border hover:border-main-blue
        first:rounded-t-md
        last:rounded-b-md
      "
      @click="goTo(row)"
    >
      <div
        class="
          p-2
          flex
          mobile:justify-center
          flex-1
          cursor-pointer
          text-center
        "
        v-for="column in columns"
        :key="column.id"
      >
        <span class="mobile:hidden font-bold mr-1">{{ column.text }}: </span>
        <template v-if="column.type === 'date'">
          {{ format(new Date(row[column.id]), "dd/MM/yyyy") }}
        </template>
        <template v-else>
          {{ row[column.id] }}
        </template>
      </div>
    </div>
    <!-- Sin resultados -->
    <div
      v-if="!loading && tableRowsSort.length === 0"
      class="w-full bg-white flex justify-center border py-5"
    >
      No hay resultados para mostrar.
    </div>
    <div v-if="loading" class="w-full bg-white flex justify-center border py-5">
      <svg
        class="animate-spin h-20 w-20"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          class="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          stroke-width="4"
        ></circle>
        <path
          class="opacity-75 text-main-blue"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
    </div>
  </div>
</template>


<script>
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch,
} from "vue";
import axios from "axios";

import router from "@/router";

import { apiListClientPayments, apiGetFilters } from "@/router/endpoints";
import CSelect from "./Select.vue";
import Datepicker from "./Datepicker.vue";
import { format, compareAsc, parseISO } from "date-fns";

export default defineComponent({
  components: {
    CSelect,
    Datepicker,
  },
  setup() {
    // Carga de datos
    let loading = ref(false);
    onMounted(() => {
      // Rows para la tabla
      getRows();

      // Rellenado de filtros
      axios.get(apiGetFilters()).then((res) => {
        const keys = ["clients", "provinces", "salesmen", "statuses"];
        keys.forEach((key) => {
          let data = Object.entries(res.data[key]);
          optionSelects[key] = data.map((item, index) => {
            return {
              index: index,
              value: item[0],
              text:
                key === "clients"
                  ? `${item[1] ? item[1].toString().trim() : item[1]} (${
                      item[0]
                    })` // Cliente (id)
                  : item[1],
            };
          });
          // Ordeno clientes
          optionSelects["clients"] = [...optionSelects["clients"]].sort(
            (a, b) => {
              return a.text.trim().toUpperCase() > b.text.trim().toUpperCase()
                ? 1
                : -1;
            }
          );
        });
      });
    });

    // Datos tabla
    const getRows = () => {
      loading.value = true;
      tableRows.value = [];
      axios
        .post(apiListClientPayments(), {
          clients: [filtrosActivos.clients].filter((item) => item !== ""),
          provinces: [filtrosActivos.provinces].filter((item) => item !== ""),
          salesmen: [filtrosActivos.salesmen].filter((item) => item !== ""),
          statuses: [filtrosActivos.statuses].filter((item) => item !== ""),
          date_from: filtrosActivos.pagoDesde,
          date_to: filtrosActivos.pagoHasta,
          from_date_received: filtrosActivos.recibidoDesde,
          till_date_received: filtrosActivos.recibidoHasta,
        })
        .then((res) => {
          tableRows.value.push(...res.data);
          loading.value = false;
        });
    };

    // + Tabla
    const columns = [
      { id: "form_id", text: "Número", type: "string" },
      { id: "client", text: "Cliente", type: "string" },
      { id: "amount", text: "Importe", type: "number" },
      { id: "submitted_date", text: "Fecha recibido", type: "date" },
      { id: "payment_date", text: "Fecha pago", type: "date" },
      { id: "status", text: "Estado", type: "string" },
    ];
    let tableRows = ref([]);
    let sortBy = ref("submitted_date");
    let sortAsc = ref(false);
    const changeSortBy = (id) => {
      if (sortBy.value === id) {
        sortAsc.value = !sortAsc.value;
      } else {
        sortAsc.value = false;
        sortBy.value = id;
      }
    };
    // Ordena las rows según sortBy y sortAsc
    const tableRowsSort = computed(() => {
      return [...tableRows.value].sort(sortRows);
    });
    const sortRows = (a, b) => {
      const type = columns.find((c) => c.id === sortBy.value).type;

      a = a[sortBy.value]?.toString().trim().toLowerCase() || 0;
      b = b[sortBy.value]?.toString().trim().toLowerCase() || 0;
      let valA = sortAsc.value ? a : b;
      let valB = sortAsc.value ? b : a;

      if (type === "string") {
        return valA > valB ? 1 : -1;
      } else if (type === "number") {
        return parseFloat(valA) - parseFloat(valB);
      } else if (type === "date") {
        valA = format(new Date(valA), "yyyy-MM-dd");
        valB = format(new Date(valB), "yyyy-MM-dd");
        return compareAsc(parseISO(valA), parseISO(valB));
      }
    };

    // + Filtros
    const titulos = {
      clients: "Clientes",
      provinces: "Departamento",
      salesmen: "Vendedor",
      statuses: "Estado",
      pagoDesde: "Pago desde",
      pagoHasta: "Pago hasta",
      recibidoDesde: "Recibido desde",
      recibidoHasta: "Recibido hasta",
    };
    const filtrosToRender = [
      { id: "clients", type: "c-select" },
      // { id: "provinces", type: "c-select" },
      { id: "salesmen", type: "c-select" },
      // { id: "statuses", type: "c-select" },
      // { id: "pagoDesde", type: "datepicker" },
      // { id: "pagoHasta", type: "datepicker" },
      { id: "recibidoDesde", type: "datepicker" },
      { id: "recibidoHasta", type: "datepicker" },
    ];
    let filtrosActivos = reactive({
      clients: "",
      provinces: "",
      salesmen: "",
      statuses: "",
      pagoDesde: null,
      pagoHasta: null,
      recibidoDesde: null,
      recibidoHasta: null,
    });
    watch(
      () => filtrosActivos,
      () => {
        getRows();
      },
      { deep: true }
    );

    let optionSelects = reactive({
      clients: [],
      provinces: [],
      salesmen: [],
      statuses: [],
    });

    // Router
    const goTo = (row) => {
      let id = row.form_id;
      router.push({ name: "avisos-de-pago-detalle", params: { id: id } });
    };

    return {
      tableRowsSort,
      goTo,
      columns,
      changeSortBy,
      sortAsc,
      sortBy,
      filtrosActivos,
      optionSelects,
      titulos,
      filtrosToRender,
      loading,
      format,
    };
  },
});
</script>

<style>
.avisos-tabla-filters-container .v3dp__datepicker{
  width: 100%;
}
</style>