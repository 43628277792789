
import { defineComponent } from "vue";
import GoBack from "@/components/GoBack.vue";

import Tabla from "@/components/PanelAvisosHomeTabla.vue";
import ButtonBlue from "./ButtonBlue.vue";

export default defineComponent({
  components: {
    Tabla,
    GoBack,
    ButtonBlue,
  },
  setup() {
    return {};
  },
});
